import { render, staticRenderFns } from "./CheckoutSuccess.vue?vue&type=template&id=2fe5b736&scoped=true"
import script from "./CheckoutSuccess.vue?vue&type=script&lang=js"
export * from "./CheckoutSuccess.vue?vue&type=script&lang=js"
import style0 from "./CheckoutSuccess.vue?vue&type=style&index=0&id=2fe5b736&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fe5b736",
  null
  
)

export default component.exports